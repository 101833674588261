<template>
  <div class="user-page">
    <div class="content-wrapper">
      <h2>Tasks / Journey</h2>
    </div>

    <b-card>
      <b-card-title>
        Plugin attivati
      </b-card-title>

      <b-row>
        <b-col cols="9" xl="6" class="pt-4">
          <vue-nestable v-model="tree">
            <template slot-scope="{ item }">
              <div
                class="nestable-whappy-item w-100 d-flex justify-content-between align-items-center pl-3"
              >
                <div class="flex justify-content-start align-items-center p-1">
                  <b-form-input
                    v-model="item.title"
                    placeholder="Enter title"
                    class="plugin-title-input"
                  ></b-form-input>
                  <div>
                    <small class="font-bold ml-4">{{ item.name }}</small>
                  </div>
                </div>

                <div
                  class="tools-box align-self-stretch d-flex align-items-center"
                >
                  <vue-nestable-handle :item="item">
                    <b-icon icon="arrows-move"></b-icon>
                  </vue-nestable-handle>
                  <b-icon
                    icon="plus"
                    class="pointer"
                    v-b-toggle.sidebar-learning
                    @click="showSelectChildPlugin(item)"
                  ></b-icon>
                  <b-icon
                    icon="gear-fill"
                    class="pointer"
                    @click="openPluginSetup(item)"
                  ></b-icon>
                  <b-icon
                    icon="trash-fill"
                    class="pointer"
                    @click="removePlugin(item)"
                  ></b-icon>
                </div>
              </div>
            </template>
          </vue-nestable>
        </b-col>
      </b-row>

      <b-row class="mt-5">
        <b-col>
          <b-button
            squared
            size="lg"
            class="button-dashed big-button"
            @click="showSelectPlugin"
            >Add new Section</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="plugin-modal"
      ref="pluginModal"
      title="Select a Plugin"
      @ok="addPlugin"
    >
      <b-list-group>
        <b-list-group-item
          v-for="(plugin, index) in pluginList"
          :key="index"
          :action="true"
          :active="selectedPlugin && plugin.name === selectedPlugin.name"
          @click="selectPlugin(plugin)"
        >
          {{ plugin.name }}
        </b-list-group-item>
      </b-list-group>
    </b-modal>

    <b-sidebar
      v-model="pluginSidebarVisible"
      class="plugin-sidebar"
      id="sidebar-plugin"
      right
      shadow
      lazy
      @hidden="resetData"
    >
      <div class="content-wrapper my-3 mx-1">
        <component :is="activePluginComponent"></component>
      </div>
    </b-sidebar>
    <b-modal v-model="showAlertModal" title="Attention!" ok-only
      >Cannot delete this item, remove all nested element before
      proceeding.</b-modal
    >
  </div>
</template>

<script>
import { VueNestable, VueNestableHandle } from "vue-nestable";

//componenti per la sidebar
import Learning from "@/views/tabs/Learning";
import SocialShare from "@/views/tabs/SocialShare.vue";
import Ordini from "@/views/yoube/Ordini";
import Prodotti from "./yoube/Prodotti.vue";
import ListaDossier from "@/views/axerta/ListaDossier";
import Surveys from "@/views/Surveys.vue";

export default {
  name: "TestTry",
  components: {
    VueNestable,
    VueNestableHandle,
    Learning,
    SocialShare,
    Ordini,
    Prodotti,
    ListaDossier,
    Surveys,
  },
  data() {
    return {
      pluginList: [
        { name: "Learning" },
        { name: "SocialShare" },
        { name: "Sales/Ordini" },
        { name: "Sales/Prodotti" },
        { name: "Dossier" },
        { name: "Survey" },
      ],
      selectedPlugin: null,
      activePluginComponent: null,
      pluginSidebarVisible: false,
      showAlertModal: false,

      tmpSection: {},

      id_internal: 1,

      tree: [],
      items: [],
      pluginSelezionati: [],
    };
  },
  methods: {
    resetData() {
      this.pluginSidebarVisible = false;
      this.tmpSection = {
        id_internal: 0,
        id: 0,
        id_parent: 0,
        order: 0,
        name: "",
      };
    },
    showSelectPlugin() {
      this.tmpSection.id_internal = this.id_internal;

      this.id_internal++;
      this.$refs.pluginModal.show();
    },
    showSelectChildPlugin(item) {
      this.tmpSection.id_internal = this.id_internal;

      this.id_internal++;
      this.tmpSection.id_parent = item.id_internal;
      this.$refs.pluginModal.show();
    },
    selectPlugin(plugin) {
      this.selectedPlugin = plugin;
    },
    addPlugin() {
      if (this.selectedPlugin) {
        this.pluginSelezionati.push({
          id_parent: this.tmpSection.id_parent,
          id_internal: this.tmpSection.id_internal,
          id: this.tmpSection.id_internal,
          name: this.selectedPlugin.name,
          title: this.selectedPlugin.name,
          order: this.items.length + 1, // imposta un ordine iniziale
        });

        this.items = [...this.pluginSelezionati];
      }

      this.tree = this.buildTree(this.items);
      this.updateList();
      this.resetData();
    },

    openPluginSetup(item) {
      // Mappa dei plugin ai rispettivi componenti
      const componentMap = {
        Learning: "Learning",
        SocialShare: "SocialShare",
        "Sales/Ordini": "Ordini",
        "Sales/Prodotti": "Prodotti",
        Dossier: "ListaDossier",
        Survey: "Surveys",
      };

      this.tmpSection = item;

      // Imposta il componente attivo nella sidebar
      this.activePluginComponent = componentMap[item.name] || null;

      // Mostra la sidebar
      this.pluginSidebarVisible = true;
    },
    removePlugin(item) {
      if (item.children && item.children.length) {
        this.showAlertModal = true;
      } else {
        this.deleteSection(item.id_internal);
      }

      this.pluginSelezionati = this.pluginSelezionati.filter(
        (p) => p.id_internal !== item.id_internal
      );
    },
    deleteSection(id_internal) {
      this.items = this.items.filter(
        (currentItem) => id_internal !== currentItem.id_internal
      );
      this.tree = this.buildTree(this.items);
    },
    buildTree() {
      let list = JSON.parse(JSON.stringify(this.items));
      if (!list) return [];

      // Ordina per il campo 'order' prima di costruire l'albero
      list.sort((a, b) => a.order - b.order);

      let map = {},
        node,
        roots = [],
        i;
      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i; // initialize the map
        list[i].children = []; // initialize the children
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.id_parent && node.id_parent !== "0") {
          if (Object.prototype.hasOwnProperty.call(map, node.id_parent)) {
            list[map[node.id_parent]].children.push(node);
          }
        } else {
          roots.push(node);
        }
      }

      return roots;
    },

    flattenTree(children, parent) {
      return Array.prototype.concat.apply(
        children.map((x) => {
          x.id_parent = parent || 0;
          return x;
        }),
        children.map((x) => this.flattenTree(x.children || [], x.id))
      );
    },
    updateList(value) {
      this.items = this.flattenTree(this.tree);
      this.tree = this.buildTree(this.items);

      // Aggiorna l'ordine in base alla posizione corrente
      this.items.forEach((item, index) => {
        item.order = index + 1;
      });

      // Rimuovi i figli per evitare duplicazioni
      let item = this.items.find((x) => x.id === value.id);
      delete item.children;
    },
  },
};
</script>

<style scoped>
.plugin-title-input {
  flex-grow: 1;
  margin-right: 10px;
}
</style>
